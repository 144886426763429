/* // @import "antd/dist/reset.css"; // Ant Design base styles */

/* // Global styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #101010;
  color: #ffffff;
  min-height: 100vh;
}

/* // Code font */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* // 🎨 Container Layouts */
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  /* width: 100%; */
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1220px;
  }
}

/* // Typography */
h1 {
  font-size: 48px;
  font-weight: lighter;
}

h2 {
  font-size: 38px;
  font-weight: lighter;
}

h3 {
  font-size: 32px;
  font-weight: lighter;
}

h4 {
  font-size: 28px;
  font-weight: lighter;
}

h5 {
  font-size: 24px;
  font-weight: lighter;
}

h6 {
  font-size: 20px;
  font-weight: lighter;
}

p {
  font-size: 20px;
  font-weight: lighter;
}

/* // Divider */
hr {
  border: none;
  height: 0.1px;
  background-color: #303030;
}

/* // 🎨 Components */
.ant-card {
  background: #1f1f1f !important;
  border-radius: 15px;
  border: none !important;
}

.ant-card-meta-title {
  white-space: initial;
}

/* // List */
.ant-list-item-meta-title {
  font-size: initial;
}

/* // Navbar */
.ant-layout-header {
  border-bottom: 0;
}

/* // Footer */
.ant-layout-footer {
  background-color: #101010;
  padding: 60px 0;
  text-align: center;
}

.ant-layout-footer a,
.ant-layout-footer h1,
.ant-layout-footer h2,
.ant-layout-footer h3 {
  text-transform: uppercase;
}

/* // Carousel */
.ant-carousel .slick-dots li button,
.ant-carousel .slick-dots li.slick-active button {
  background: #e2e2e2;
}

/* // 🎀 Ribbon */
.ant-ribbon {
  border-radius: 15px !important;
}

.ant-ribbon-corner {
  display: none;
}

/* // Collapse */
.ant-collapse {
  background: #101010 !important;
}

.ant-collapse,
.ant-collapse-item,
.ant-collapse-header,
.ant-collapse-content {
  border: none !important;
}

/* // App Layout */
.App {
  padding-top: 0;
  min-height: 100vh;
}

#root {
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
}

/* // White text helper class */
.white-text {
  color: #ffffff;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #ffffff;
  }
}

/* // Slides */
.slide {
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding: min(8vh, 96px) 0 min(8vh, 96px) 0;
}

.slide-first {
  padding: 0 0 min(8vh, 96px) 0;
}

/* // Centering Utility */
.centered {
  text-align: center;
}

/* // 🎇 Jumbotron */
.jumbotron {
  padding: 80px 40px;
  background-color: #080808;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.jumbotron h1,
.jumbotron h2,
.jumbotron h3,
.jumbotron h4,
.jumbotron h5,
.jumbotron h6 {
  text-transform: uppercase;
}

.jumbotron-content {
  max-width: 50%;
}

@media (max-width: 767px) {
  .jumbotron-content {
    max-width: 100%;
  }
}

/* // Dotted background */
.dotted {
  background-image: radial-gradient(#ffffff25 1px, transparent 0);
  background-size: 30px 30px;
  background-position: -19px -19px;
}

/* // 🎞 Animated grain effect */
.cgrain {
  opacity: 0.6;
  animation: grain 8s steps(10) infinite;
  content: "";
  height: 300%;
  left: -50%;
  position: fixed;
  top: -100%;
  width: 300%;
  z-index: 300;
  pointer-events: none;
}

@keyframes grain {

  0%,
  100% {
    transform: translate(0, 0)
  }

  10% {
    transform: translate(-5%, -10%)
  }

  20% {
    transform: translate(-15%, 5%)
  }

  30% {
    transform: translate(7%, -25%)
  }

  40% {
    transform: translate(-5%, 25%)
  }

  50% {
    transform: translate(-15%, 10%)
  }

  60% {
    transform: translate(15%, 0%)
  }

  70% {
    transform: translate(0%, 15%)
  }

  80% {
    transform: translate(3%, 35%)
  }

  90% {
    transform: translate(-10%, 10%)
  }
}

.logo {
  max-height: 100px;
  max-width: max(15%, 200px);
}

/* // 🎨 Set Orange Theme for Links & Icons */
a {
  color: #e86900;
  text-decoration: none;
}

a:hover {
  color: #FA8C16;
}

.ant-btn-primary {
  background-color: #e86900 !important;
  border-color: #e86900 !important;
  border-radius: 15px;
}

/* .anticon {
   color: #e86900 !important; // Force Ant Design icons to use orange
} */

.ant-layout-footer a {
  color: #e86900;
}

.ant-layout-footer a:hover {
  color: #FA8C16;
}

/* Blog Card Styling */
.blog-card-cover {
  background-color: #1f1f1f;
  height: 200px;
  border-radius: 15px 15px 0 0;
}

/* Ant image full height */
.ant-image {
  height: 100%;
  width: 100%;
}